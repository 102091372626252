<template>
  <div>
    <el-dialog title="新增课室"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="80px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="课室名称"
                          prop="room_name">
              <el-input placeholder="请输入班级名称"
                        v-model="form.room_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="公告"
                          prop="notice">
              <el-input placeholder="公告"
                        v-model="form.notice"
                        type="textarea"
                        :rows="4"
                        style="width:100%" />
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      gradeList: [],
      rules: {
        room_name: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
      },
    }
  },
  computed: {
  },
  methods: {
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        this.$http({
          url: '/api/v2/special/room_add',
          method: 'post',
          data: this.form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
.warp {
  display: flex;
  margin-top: 20px;
  div {
    &:nth-child(1) {
      flex: 1;
      margin-left: 8px;
    }
    &:nth-child(2) {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>